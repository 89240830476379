import axios from 'axios';

const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
export const CardRequestService = {

    getAllCardRequest: (page) => {
        const token = getToken();
        return axios.get(`https://api.paytaps.com/admin/card-request/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },


    showCardRequestById: (Id) => {
        const token = getToken();
        return axios.get(`https://api.paytaps.com/admin/card-request/${Id}`, {
            headers: {
                Authorization: `Bearer ${token}`, // Set the Authorization header
            },
        });
    },


    editCardRequestById(id,cardData) {
        const token = getToken();
        return axios.patch(`https://api.paytaps.com/admin/card-request/edit/${id}`, cardData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },

    // approveCardRequest(id,cardData){
    //     return axios.patch(`https://api.paytaps.com/admin/card-request/status/${id}`, cardData, {
    //         headers: {
    //             Authorization: `Bearer ${token}` // Set the Authorization header
    //         }
    //     }); 
    // }
    approveCardRequest(id,cardData){
        const token = getToken();
        return axios.post(`https://api.paytaps.com/admin/card/create/${id}`, cardData, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        }); 
    }

};