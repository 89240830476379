import React, { useEffect, useState } from 'react';
import { CardRequestService } from '../../../services/CardRequestService';
import { Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './additcss.css';
import { format } from 'date-fns';
import swal from "sweetalert";
import MasterWallet from '../MasterWalletBalance/MasterWallet';

const ShowCardDetails = () => {
    const { Id } = useParams();
    const [cardData, setCardData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [approvalStatus, setApprovalStatus] = useState('');

    const navigate=useNavigate();

    useEffect(() => {
        if (Id) {
            setLoading(true);
            CardRequestService.showCardRequestById(Id)
                .then((response) => {
                    console.log('API Response:', response.data); // Check the API response
                    const { data } = response.data;
                    if (Array.isArray(data) && data.length > 0) {
                        setCardData(data[0]); // Set the first item from the array
                    } else {
                        setError(new Error('No data found'));
                    }
                })
                .catch(error => {
                    console.error('Error fetching card data:', error);
                    setError(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            console.error('ID is undefined');
            setError(new Error('ID is undefined'));
            setLoading(false);
        }
    }, [Id]);


    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        // Add save logic here
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        // Optionally reset form data if necessary
    };

    const handleBack = () => {
      navigate("/Cardrequest");
    };





    const handleApproveRequest = () => {
      const isConfirmed = window.confirm("Are you sure you want to approve this request?");
      setApprovalStatus("Success");

      if (isConfirmed) {
        CardRequestService.approveCardRequest(Id,{ status: approvalStatus })
        .then(response => {
          console.log('Approval successful:', response);
          swal("Good job!", "card request approved successfully", "success")
        })
        .catch(error => {
          const errorMessage = error.response?.message || 'An unexpected error occurred';
          swal("Ooops!", errorMessage, "error");
      });
      }
  };



    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;
    if (!cardData) return <p>No card data found.</p>;

    const formatDate = (date) => {
      if (!date) return ''; // Return an empty string if the date is null or undefined
      try {
          return format(new Date(date), 'yyyy-MM-dd'); // Try to format the date
      } catch (error) {
          console.error('Invalid date:', date, error);
          return ''; // Return an empty string if the date is invalid
      }
  };

    const formattedDateOfBirth = formatDate(cardData?.client?.dateOfBirth.date);




    return (

        <Fragment>
          { cardData? (
          
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Card request detail</h4>
              <MasterWallet/>
              <button className="btn btn-secondary" type="button" onClick={handleBack}>Back to list</button>

            </div>
            <div className="card-body">
              <div className="basic-form">
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="form-group mb-3 col-md-6">
                      <label>first Name</label>
                      <input
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`}                        
                        type="text"
                        value={cardData.client.firstName}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, firstName: e.target.value })}

                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>last Name</label>
                       <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.lastName}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, lastName: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>emailAddress</label>
                       <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.emailAddress}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, emailAddress: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>preferredName</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.preferredName}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, preferredName: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>nationality</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.nationality}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, nationality: e.target.value })}
                      />
                    </div>                    
                    <div className="form-group mb-3 col-md-6">
                      <label>countryCode</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.countryCode}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, countryCode: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>gender</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.gender}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, gender: e.target.value })}
                      />
                    </div>                   
                    <div className="form-group mb-3 col-md-6">
                      <label>mobile</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.mobile}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, mobile: e.target.value })}
                      />
                    </div>                   
                     <div className="form-group mb-3 col-md-6">
                      <label>dateOfBirth</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={formattedDateOfBirth}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, dateOfBirth: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>buillingAddress</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.buillingAddress}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, buillingAddress: e.target.value })}
                      />
                    </div>
                    <div className="form-group mb-3 col-md-6">
                      <label>buillingAddress</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.buillingCity}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, buillingCity: e.target.value })}
                      />
                    </div>                    
                    <div className="form-group mb-3 col-md-6">
                      <label>buillingAddress</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.buillingCountry}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, buillingCountry: e.target.value })}
                      />
                    </div>                    
                    <div className="form-group mb-3 col-md-6">
                      <label>buillingAddress</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.buillingZipCode}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, buillingZipCode: e.target.value })}
                      />
                    </div>                    
                    <div className="form-group mb-3 col-md-6">
                      <label>buillingAddress</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.buillingState}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, buillingState: e.target.value })}
                      />
                    </div>                    
                    <div className="form-group mb-3 col-md-6">
                      <label>buillingAddress</label>
                      <input
                        type="text"
                        className={`form-control ${!isEditing ? 'disabled-input' : ''}`} 
                        value={cardData.client.deliveryState}
                        disabled={!isEditing}
                        onChange={(e) => setCardData({ ...cardData, deliveryState: e.target.value })}
                      />
                    </div>
                </div>
                <div>
                {!isEditing ? (
                        <button className="btn btn-primary" type="button" onClick={handleEditClick}>Edit</button>
                    ) : (
                        <>
                            <button className="btn btn-primary" type="button" onClick={handleSaveClick}>Save</button>
                            <button className="btn btn-primary" type="button" onClick={handleCancelClick}>Cancel</button>
                        </>
                    )}
                      {cardData.requesteStatus === 'Success' ? (
                            <span className="badge bg-success" style={{ float: 'right' }}>Approved</span>
                        ) : (
                            <button className="btn btn-secondary" style={{ float: 'right' }} type="button" onClick={handleApproveRequest}>
                                Approve Request
                            </button>
                        )}                                  
                      </div>
                </form>
              </div>
            </div>
          </div>
        </div>
          ):(<div className="">no data</div>)
        }
        </Fragment>
    );
};

export default ShowCardDetails;
