import axios from 'axios';

const getToken = () => {
    const tokenHeader = localStorage.getItem('Header');
    const tokenPayload = localStorage.getItem('payload');
    const tokenSignature = localStorage.getItem('sig');

    return `${tokenHeader}.${tokenPayload}.${tokenSignature}`;
};
const token = getToken();

export const ParamaterService = {

    getAllParams:(page) =>{
        const token = getToken();
        return axios.get(`https://api.paytaps.com/admin/parameter/?page=${page}`, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    addParameter:(data) =>{
        const token = getToken();
        return axios.post(`https://api.paytaps.com/admin/parameter/add`, data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    },
    editParameter:(id,data) =>{
        const token = getToken();
        return axios.patch(`https://api.paytaps.com/admin/parameter/edit/${id}`, data, {
            headers: {
                Authorization: `Bearer ${token}` // Set the Authorization header
            }
        });
    }
};